import { useEffect, useState } from "react";

/**
 * Helper used to determan if app is running client or server side
 *
 * @returns boolean
 */
export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(typeof document !== "undefined");
  }, []);

  return isClient;
};
