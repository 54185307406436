import { useStaticQuery, graphql } from "gatsby";

/**
 * Retrieve all of our Gravity Form forms and associated fields.
 * Missing a form? Make sure it's included in the gravity-forms source plugin included array, check gatsby-node.js
 */

export const useAllGravityForms = () => {
  const { allGfForm } = useStaticQuery(
    graphql`
      query AllGravityForms {
        allGfForm {
          nodes {
            title
            description
            formId
            apiURL
            labelPlacement
            is_active
            slug
            confirmations {
              type
              url
              name
              message
              queryString
              pageId
              isDefault
              id
            }
            formFields {
              type
              placeholder
              visibility
              labelPlacement
              label
              isRequired
              inputType
              inputMask
              defaultValue
              description
              displayOnly
              choices
              subLabelPlacement
              maxLength
              enablePasswordInput
              enableCalculation
              emailConfirmEnabled
              adminLabel
              disableQuantity
              descriptionPlacement
              displayAllCategories
              inputMaskValue
              size
              inputName
              formId
              errorMessage
              fields
              id
              allowsPrepopulate
              calculationFormula
              calculationRounding
              conditionalLogic
              content
              cssClass
              maxFiles
              inputMaskIsCustom
              multipleFiles
              noDuplicates
              pageNumber
              productField
              useRichTextEditor
            }
          }
        }
      }
    `
  );
  return allGfForm.nodes;
};
